import { FC, MutableRefObject, useEffect, useRef } from 'react';
import { Checkbox, DatePicker, Form, InputRef, Tag } from 'antd';
import { Moment } from 'moment';
import { COLORS } from 'constants/colors';
import DrawerAtom from 'components/atoms/DrawerAtom';
import TextAtom from 'components/atoms/TextAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { Drawer, UpdateCheckInInitialStatesModel, UpdateCheckInInputFormKey } from 'hooks/useCheckInUpdateRoom/models';
import InputAtom from 'components/atoms/InputAtom';
import { Icon } from 'components/atoms/Icons';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import './styles.scss';

const DRAWER_ATOM_WIDTH = '480px';

export interface CheckInUpdateRoomDrawerFormValues {
  room: string,
  [UpdateCheckInInputFormKey.EMAILS]: string[];
  [UpdateCheckInInputFormKey.RANGE]: Moment[];
  [UpdateCheckInInputFormKey.START_TIME]: Moment;
  [UpdateCheckInInputFormKey.END_TIME]: Moment;
  [UpdateCheckInInputFormKey.CARD_1]: string;
  [UpdateCheckInInputFormKey.CARD_2]: string;
  [UpdateCheckInInputFormKey.CARD_3]: string;
}

export interface CheckInUpdateRoomDrawerProps extends UpdateCheckInInitialStatesModel {
  drawer: Drawer
}

const CheckInUpdateRoomDrawer: FC<CheckInUpdateRoomDrawerProps> = ({
  drawer,
  digitalBox,
  emails,
  cardListBox,
  card1,
  card2,
  card3,
  endTime,
  startTime,
  rangeDate,
}) => {
  const card1Ref = useRef<InputRef | null>(null);
  const card2Ref = useRef<InputRef | null>(null);
  const card3Ref = useRef<InputRef | null>(null);

  const refs: { [key: string]: MutableRefObject<InputRef | null> } = {
    [UpdateCheckInInputFormKey.CARD_1]: card1Ref,
    [UpdateCheckInInputFormKey.CARD_2]: card2Ref,
    [UpdateCheckInInputFormKey.CARD_3]: card3Ref,
  }

  useEffect(() => {
    cardListBox.isContentVisible && card1Ref.current?.focus();
  }, [cardListBox.isContentVisible]);

  return (
    <DrawerAtom
      width={DRAWER_ATOM_WIDTH}
      className={'CheckInUpdateRoomDrawer__wrapper'}
      title={
        <TextAtom style={{ color: COLORS.text }}>{drawer.title}</TextAtom>
      }
      destroyOnClose={true}
      closable={!drawer.isLoading}
      maskClosable={!drawer.isLoading}
      onClose={drawer.onClose}
      placement={'right'}
      size={'large'}
      open={drawer.isVisible}
    >
      <main className={'CheckInUpdateRoomDrawer__main'}>
        <div className={'CheckInUpdateRoomDrawer__main-wrapper'}>
          <div className={'CheckInUpdateRoomDrawer__main-wrapper-input'}>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-label'}>{rangeDate.label} *</div>
            <Form.Item
              validateStatus={rangeDate.isError ? 'error' : undefined}
              help={rangeDate.errorMessage}
            >
              <DatePicker.RangePicker
                className={'CheckInUpdateRoomDrawer__main-wrapper-input-range-picker'}
                disabledDate={rangeDate.onDisabledDate}
                format={rangeDate.formatDate}
                disabled={rangeDate.isDisabled}
                allowClear
                defaultValue={rangeDate.values as any}
                onChange={rangeDate.onChangeRange as any}
              />
            </Form.Item>
          </div>
          <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-time'}>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-time-picker-input'}>
              <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-label'}>{startTime.label} *</div>
              <Form.Item
                validateStatus={startTime.isError ? 'error' : undefined}
                help={startTime.errorMessage}
              >
                <DatePicker.TimePicker
                  className={'CheckInUpdateRoomDrawer__main-wrapper-input-time-picker'}
                  disabledTime={startTime.onDisabledTime}
                  format={startTime.formatTime}
                  disabled={startTime.isDisabled}
                  onChange={startTime.onChangeTime}
                  defaultValue={startTime.value || undefined}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-time-picker-input'}>
              <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-label'}>{endTime.label} *</div>
              <Form.Item
                validateStatus={endTime.isError ? 'error' : undefined}
                help={endTime.errorMessage}
              >
                <DatePicker.TimePicker
                  className={'CheckInUpdateRoomDrawer__main-wrapper-input-time-picker'}
                  disabledTime={endTime.onDisabledTime}
                  format={endTime.formatTime}
                  disabled={endTime.isDisabled}
                  onChange={endTime.onChangeTime}
                  defaultValue={endTime.value || undefined}
                  allowClear
                />
              </Form.Item>
            </div>
          </div>
          <div>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-label'}>{drawer.keysSectionTitle}:</div>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-description'}>{drawer.keysSectionDescription}</div>
            <div
              className={
                `CheckInUpdateRoomDrawer__main-box ${digitalBox.isContentVisible && 'CheckInUpdateRoomDrawer__main-box--open'}`
              }
            >
              <div className={'CheckInUpdateRoomDrawer__main-box-title-wrapper'}>
                <div className={'CheckInUpdateRoomDrawer__main-box-title'}>{digitalBox.title}</div>
                <Checkbox
                  checked={digitalBox.isContentVisible}
                  disabled
                />
              </div>
              {digitalBox.isContentVisible && (
                <div className={'CheckInUpdateRoomDrawer__email-tag-list'}>
                  {emails.valueList.map(emailTag => (
                    <Tag className={'CheckInUpdateRoomDrawer__email-tag'}>
                      {emailTag}
                    </Tag>
                  ))}
                </div>
              )}
            </div>
            <div className={'CheckInUpdateRoomDrawer__main-box CheckInUpdateRoomDrawer__main-box--open'}>
              <div className={'CheckInUpdateRoomDrawer__main-box-title-wrapper'}>
                <div className={'CheckInUpdateRoomDrawer__main-box-title'}>{cardListBox.title}</div>
                <Checkbox
                  checked={cardListBox.isContentVisible}
                  disabled
                />
              </div>
              {cardListBox.isContentVisible && (
                <div>
                  {[card1, card2, card3].map((card) => {
                    const label = `${card.label} ${card.isRequired ? '*' : ''}`;
                    return (
                      <>
                        <div className={'CheckInUpdateRoomDrawer__main-box-label'}>{label}</div>
                        <Form.Item
                          validateStatus={card.isError ? 'error' : undefined}
                          help={card.errorMessage}
                        >
                          <InputAtom
                            style={{ width: '85%' }}
                            ref={refs[card.key]}
                            onChange={(event) => card.onChangeValue(event.target.value)}
                            value={card.value}
                            placeholder={card.placeholder}
                          />
                          <DefaultButton
                            icon={<Icon.QR />}
                            onClick={() => drawer.onShowQr(card.key)}
                          />
                        </Form.Item>
                      </>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='CheckInUpdateRoomDrawer__required-fields'>{`(*) ${drawer.requiredFields}`}</div>
      </main>
      <footer>
        <DefaultButton
          disabled={drawer.isLoading}
          onClick={drawer.onClose}
          title={drawer.closeButtonText} />
        <PrimaryButton
          disabled={drawer.isDisabledSaveButton}
          loading={drawer.isLoading}
          onClick={drawer.onSave}
          title={drawer.saveButtonText}
        />
      </footer>
    </DrawerAtom>
  );
}

export default CheckInUpdateRoomDrawer;
