import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAnonymousAccess } from 'hooks/useAnonymousAccess';
import { AccessColumn, AccessDeviceEditTableModel } from '../Access/AccessColumn';
import { useAccessPasswordsDrawer } from './useAccessPasswords';
import { GetAssetDetail } from 'core/domain/assets/repositories/getAssetDetail';
import { UserRole, UserRoleType } from 'models/users.model';
import { AssetVerticalsEnum } from 'core/domain/assets/models';
import { useCheckInRoom } from 'hooks/useCheckInRoom/useCheckInRoom';
import UserProfileContext, { UserProfileContextState } from 'context/UserProfileContext';
import { useDeviceHelpDrawer } from './useDeviceHelpDrawer';
import { useAccessDeviceEdit } from './useAccessDeviceEdit';

export const useAccess = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { userProfile } = useContext<UserProfileContextState>(UserProfileContext);
  const [assetType, setAssetType] = useState<AssetVerticalsEnum>(AssetVerticalsEnum.HOME);
  const [assetName, setAssetName] = useState<string>('');
  const [isAssignRoomButtonVisible, setIsAssignRoomButtonVisible] = useState<boolean>(false);
  const { checkInStates } = useCheckInRoom();
  const {
    invitations,
    pageNumber,
    listInvitationsModalTranslations,
    listInvitationItemTranslations,
    createModalTranslations,
    createBetaInvitationButtonText,
    onRevoke,
    onLoadMore,
    onCloseInvitationsListModal,
    onOpenInvitationsListModal,
    onOpenCreateInvitationModal,
    onCloseCreateInvitationModal,
    onOpenCreateBetaInvitationModal,
    onCloseCreateBetaInvitationModal,
    loading: loadingAccesses,
    showLoadMoreInvitationsButton,
    invitationListModalVisible,
    createInvitationModalVisible,
    createBetaInvitationModalVisible,
  } = useAnonymousAccess(assetId);
  const {
    adminPasswords,
    adminAvailablePasswords,
    nfcPasswords,
    userPasswords,
    userAvailablePasswords,
    drawerTranslations,
    deleteModalTranslations,
    closeModalTranslations,
    onCloseDrawer,
    onCloseDrawerModal,
    onConfirmCloseDrawer,
    onClickDevice,
    onTogglePassword,
    onChangePassword,
    onCloseDeleteModal,
    onDeletePassword,
    onClickConfirmDeletingPassword,
    onClickAddPassword,
    onSavePasswordsChanges,
    onConfirmCloseDrawerModalWithoutSaving,
    drawerPasswordVisible,
    adminPasswordsAddButtonVisible,
    userPasswordsAddButtonVisible,
    nfcPasswordsAddButtonVisible,
    confirmDeletingModalVisible,
    closeDrawerModalVisible,
    adminSectionVisible,
    userSectionVisible,
    nfcSectionVisible,
    updateSuccess,
    loadingUpdate,
    saveButtonDisabled,
  } = useAccessPasswordsDrawer();
  const {
    controlsDeviceHelpDrawer,
    formDeviceHelpDrawer,
  } = useDeviceHelpDrawer();
  const { 
    deviceList,
    currentDevice,
    getDeviceList,
    onCurrentDevice,
    loading
  } = useAccessDeviceEdit();

  const assignRoomButtonText = t('_ACCESS_ASSIGN_ROOM');

  const isUserPropertyManager = !!userProfile?.roles.includes(UserRoleType.ROLE_ASSETS_MANAGER_ADMIN as UserRole);
  const isAssetTypeHome = assetType === AssetVerticalsEnum.HOME;
  const isCreateAndListInvitationsForbidden = isUserPropertyManager && isAssetTypeHome;

  const onClickPasswordSettings = (device: AccessDeviceEditTableModel): void => {
    const deviceName = !!device.deviceName ? device.deviceName : t('_NAME_NOT_REPORTED');
    const deviceType = !!device.deviceType ? device.deviceType : t('not_reported');
    const newDevice = { ...device, deviceName, deviceType };
    onCurrentDevice(newDevice);
    onClickDevice(newDevice);
  };

  const onClickInstructionsSettings = (device: AccessDeviceEditTableModel) => {
    controlsDeviceHelpDrawer.onOpen({
      deviceName: device.deviceName,
      deviceGatewayId: device.deviceGatewayId,
      deviceId: device.deviceId,
    });
  };

  const accessColumn = AccessColumn({ 
    onClickPasswordSettings,
    onClickInstructionsSettings,
  });

  useEffect(() => {
    GetAssetDetail(assetId).then((response) => {
      response && setAssetType(response.data.type as AssetVerticalsEnum);
      response && setAssetName(response.data.alias);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    setIsAssignRoomButtonVisible(assetType === AssetVerticalsEnum.HOME || assetType === AssetVerticalsEnum.HOTEL_ROOM);
  }, [assetType]);

  useEffect(() => {
    !!updateSuccess && getDeviceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  const onOpenAssignRoomDrawer = () => {
    checkInStates.drawer.onCheckInRoom(assetName, assetId);
  };

  return {
    assetId,
    devices: deviceList,
    currentDevice,
    accessColumn,
    invitations,
    pageNumber,
    form,
    createModalTranslations,
    createBetaInvitationButtonText,
    drawerTranslations,
    adminPasswords,
    adminAvailablePasswords,
    nfcPasswords,
    userPasswords,
    userAvailablePasswords,
    deleteModalTranslations,
    closeModalTranslations,
    listInvitationsModalTranslations,
    listInvitationItemTranslations,
    assignRoomButtonText,
    checkInStates,
    controlsDeviceHelpDrawer,
    formDeviceHelpDrawerDescriptionState: formDeviceHelpDrawer.description,
    formDeviceHelpDrawerTitleState: formDeviceHelpDrawer.title,
    onOpenCreateInvitationModal,
    onCloseCreateInvitationModal,
    onOpenCreateBetaInvitationModal,
    onCloseCreateBetaInvitationModal,
    onCloseInvitationsListModal,
    onOpenInvitationsListModal,
    onOpenAssignRoomDrawer,
    onLoadMore,
    onRevoke,
    onClosePasswordsDrawer: onCloseDrawer,
    onClosePasswordsDrawerModal: onCloseDrawerModal,
    onConfirmClosePasswordDrawer: onConfirmCloseDrawer,
    onTogglePassword,
    onChangePassword,
    onCloseDeleteModal,
    onDeletePassword,
    onClickConfirmDeletingPassword,
    onClickAddPassword,
    onSavePasswordsChanges,
    onConfirmCloseDrawerModalWithoutSaving,
    onGetSensorsList: getDeviceList,
    isAssignRoomButtonVisible,
    loading,
    loadingAccesses,
    createInvitationModalVisible,
    createBetaInvitationModalVisible,
    invitationListModalVisible,
    drawerPasswordVisible,
    adminPasswordsAddButtonVisible,
    userPasswordsAddButtonVisible,
    nfcPasswordsAddButtonVisible,
    confirmDeletingModalVisible,
    closeDrawerModalVisible,
    adminSectionVisible,
    userSectionVisible,
    nfcSectionVisible,
    loadingUpdate,
    saveButtonDisabled,
    showLoadMoreInvitationsButton,
    isCreateAndListInvitationsForbidden,
  };
};
