import { useReducer, Reducer, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import i18n from 'config/i18n';
import { TIME_FORMAT } from 'constants/date';
import { UpdateCheckInRoomModel } from 'core/domain/hotels/models';
import { UpdateCheckInRoom } from 'core/domain/hotels/repository/updateCheckInRoom';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';
import { CheckInHistoryCustomDataModel } from 'components/pages/AccessesVerticals/hotel/CheckInListTab/resources/utils';
import { messageAtom } from 'components/atoms/MessageAtom';
import {
  UpdateCheckInFormBoxProps,
  UpdateCheckInFormTextInputCardsProps,
  UpdateCheckInFormTextInputRangeProps,
  UpdateCheckInFormTextInputTimeProps,
  UpdateCheckInFormTextInputValueProps,
  UpdateCheckInFormEmailInputValueProps,
  UpdateCheckInQrModalProps,
  UpdateCheckInInputFormKey,
  UpdateCheckInInitialStatesModel,
  Drawer,
  UpdateCardKey,
} from './models';
import {
  getFollowingKeyUpdate,
  getInitialCardListBox,
  getInitialDigitalBox,
  getInitialEmails,
  getInitialEndTime,
  getInitialRange,
  getInitialStartTime,
  getInitialUpdateCard,
  getInitialUpdateQrModal,
} from './utils';

const DELAY_FOLLOWING_CARD = 1000;

export const accessHotelUpdateCheckInitialState: UpdateCheckInInitialStatesModel = {
  [UpdateCheckInInputFormKey.QR_MODAL]: getInitialUpdateQrModal(),
  [UpdateCheckInInputFormKey.RANGE]: getInitialRange(),
  [UpdateCheckInInputFormKey.START_TIME]: getInitialStartTime(),
  [UpdateCheckInInputFormKey.END_TIME]: getInitialEndTime(),
  [UpdateCheckInInputFormKey.DIGITAL_BOX]: getInitialDigitalBox(),
  [UpdateCheckInInputFormKey.EMAILS]: getInitialEmails(),
  [UpdateCheckInInputFormKey.CARD_LIST_BOX]: getInitialCardListBox(),
  [UpdateCheckInInputFormKey.CARD_1]: getInitialUpdateCard({
    label: i18n.t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_1_LABEL'),
    key: UpdateCheckInInputFormKey.CARD_1,
    isRequired: true,
  }),
  [UpdateCheckInInputFormKey.CARD_2]: getInitialUpdateCard({
    label: i18n.t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_2_LABEL'),
    key: UpdateCheckInInputFormKey.CARD_2,
  }),
  [UpdateCheckInInputFormKey.CARD_3]: getInitialUpdateCard({
    label: i18n.t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_3_LABEL'),
    key: UpdateCheckInInputFormKey.CARD_3,
  }),
};

export interface Action {
  key: string;
  payload: UpdateCheckInQrModalProps |
    UpdateCheckInFormTextInputRangeProps |
    UpdateCheckInFormTextInputTimeProps |
    UpdateCheckInFormBoxProps |
    UpdateCheckInFormTextInputValueProps |
    UpdateCheckInFormEmailInputValueProps |
    UpdateCheckInFormTextInputCardsProps;
}

const reducer = (state: UpdateCheckInInitialStatesModel, action: Action) => ({ ...state, [action.key]: action.payload });

const validateEmailFormat = async (value: string): Promise<boolean> => {
  const email = value.trim();
  const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailValidationRegex.test(email);
};

export const useUpdateCheckInRoom = () => {
  const { t } = useTranslation();
  const { token } = useUserSession();
  const { host } = useEnvironment();
  const [accessUid, setAccessUid] = useState<string>('');
  const [title, setTitle] = useState<string>(t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_TITLE'));
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [selectedRoom, setSelectedRoom] = useState<{ name: string, id: string }>({ name: '', id: '' });
  const [
    updateCheckInStates, dispatch
  ] = useReducer<Reducer<UpdateCheckInInitialStatesModel, Action>>(
    reducer, accessHotelUpdateCheckInitialState
  );
  
  const rangeState = updateCheckInStates[UpdateCheckInInputFormKey.RANGE];
  const startTimeState = updateCheckInStates[UpdateCheckInInputFormKey.START_TIME];
  const endTimeState = updateCheckInStates[UpdateCheckInInputFormKey.END_TIME];
  const digitalBoxState = updateCheckInStates[UpdateCheckInInputFormKey.DIGITAL_BOX];
  const cardListBoxState = updateCheckInStates[UpdateCheckInInputFormKey.CARD_LIST_BOX];
  const emailsState = updateCheckInStates[UpdateCheckInInputFormKey.EMAILS];
  const card1State = updateCheckInStates[UpdateCheckInInputFormKey.CARD_1];
  const card2State = updateCheckInStates[UpdateCheckInInputFormKey.CARD_2];
  const card3State = updateCheckInStates[UpdateCheckInInputFormKey.CARD_3];
  const qrModalState = updateCheckInStates[UpdateCheckInInputFormKey.QR_MODAL];

  const closeButtonText = t('_ACCESS_HOTEL_UPDATE_DRAWER_CLOSE');
  const keysSectionDescription = t('_ACCESS_HOTEL_UPDATE_DRAWER_KEYS_SECTION_DESCRIPTION');
  const keysSectionTitle = t('_ACCESS_HOTEL_UPDATE_DRAWER_KEYS_SECTION_TITLE');
  const requiredFields = t('required_fields');
  const saveButtonText = t('_ACCESS_HOTEL_UPDATE_DRAWER_SAVE');
  const checkInCard1Label = t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_1_LABEL');
  const checkInCard2Label = t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_2_LABEL');
  const checkInCard3Label = t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_3_LABEL');
  const checkInRequiredFieldErrorText = t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_REQUIRED_FIELD');
  const checkInEmailErrorFormatText = t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_EMAIL_ERROR_FORMAT');

  const setResetAllPayload = (key: UpdateCheckInInputFormKey) => {
    dispatch({
      key,
      payload: accessHotelUpdateCheckInitialState[key]
    });
  }

  const onUpdateCheckInRoom = (item: CheckInHistoryCustomDataModel) => {
    setAccessUid(item.id);
    setIsEdited(false);

    if (item.isDigital) {
      dispatch({
        key: UpdateCheckInInputFormKey.DIGITAL_BOX,
        payload: {
          ...getInitialDigitalBox(),
          isContentVisible: true,
        }
      });
      dispatch({
        key: UpdateCheckInInputFormKey.EMAILS,
        payload: {
          ...getInitialEmails(),
          valueList: item.emails,
        }
      })
    }
    dispatch({
      key: UpdateCheckInInputFormKey.CARD_LIST_BOX,
      payload: {
        ...getInitialCardListBox(),
        isContentVisible: true,
      }
    });
    dispatch({
      key: UpdateCheckInInputFormKey.CARD_1,
      payload: {
        ...getInitialUpdateCard({
          label: i18n.t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_1_LABEL'),
          key: UpdateCheckInInputFormKey.CARD_1,
          isRequired: true,
        }),
        value: item.nfcCodes[0],
      }
    });
    dispatch({
      key: UpdateCheckInInputFormKey.CARD_2,
      payload: {
        ...getInitialUpdateCard({
          label: i18n.t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_2_LABEL'),
          key: UpdateCheckInInputFormKey.CARD_2
        }),
        value: item.nfcCodes[1] || '',
      }
    });
    dispatch({
      key: UpdateCheckInInputFormKey.CARD_3,
      payload: {
        ...getInitialUpdateCard({
          label: i18n.t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_3_LABEL'),
          key: UpdateCheckInInputFormKey.CARD_3
        }),
        value: item.nfcCodes[2] || '',
      }
    });
    dispatch({
      key: UpdateCheckInInputFormKey.START_TIME,
      payload: {
        ...getInitialStartTime(),
        isDisabled: true,
        value: moment(moment(), TIME_FORMAT)
      }
    });
    dispatch({
      key: UpdateCheckInInputFormKey.END_TIME,
      payload: {
        ...getInitialEndTime(),
        isDisabled: true,
      }
    });
    dispatch({
      key: UpdateCheckInInputFormKey.RANGE,
      payload: {
        ...getInitialRange(),
        isDisabled: true,
        values: [moment.unix(item.validFrom), moment.unix(item.validUntil)]
      }
    })
    setTitle(t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_TITLE_ROOM_NAME', { roomName: item.assets[0].alias }));
    setSelectedRoom({ name: item.assets[0].alias, id: item.assets[0].assetId });
    setIsVisible(true);
    setIsLoading(false);
  }

  const onClose = () => {
    setIsVisible(false);
    setIsEdited(false);
  }

  const onChangeRange = (values: Moment[] | null) => {
    dispatch({
      key: UpdateCheckInInputFormKey.RANGE,
      payload: {
        ...rangeState,
        isError: !values?.length,
        errorMessage: !values?.length ? checkInRequiredFieldErrorText : '',
        values: values ?? [],
      }
    });
  }

  const setResetError = (key: UpdateCheckInInputFormKey) => {
    dispatch({
      key,
      payload: {
        ...updateCheckInStates[key],
        isError: false,
        errorMessage: '',
      } as UpdateCheckInFormBoxProps |
           UpdateCheckInFormTextInputTimeProps |
           UpdateCheckInFormTextInputCardsProps |
           UpdateCheckInFormTextInputRangeProps |
           UpdateCheckInFormTextInputValueProps
    });
  }

  const onChangeTime = (key: UpdateCheckInInputFormKey, value: Moment | null) => {
    dispatch({
      key,
      payload: {
        ...updateCheckInStates[key],
        isError: !value,
        errorMessage: !value ? checkInRequiredFieldErrorText : '',
        value,
      } as UpdateCheckInFormTextInputTimeProps
    });
  }

  const onChangeStartTime = (value: Moment | null) => {
    !!value && setResetError(UpdateCheckInInputFormKey.START_TIME);
    onChangeTime(UpdateCheckInInputFormKey.START_TIME, value)
  }

  const onChangeEndTime = (value: Moment | null) => {
    !!value && setResetError(UpdateCheckInInputFormKey.END_TIME);
    onChangeTime(UpdateCheckInInputFormKey.END_TIME, value)
  }

  const onShowDigitalBoxContent = (isShown: boolean) => {
    const email = emailsState.value.trim();
    
    if (isShown || !cardListBoxState.isContentVisible) {
      setIsDisabledSaveButton(!isShown);
    }
    
    !email && (
      dispatch({
        key: UpdateCheckInInputFormKey.EMAILS,
        payload: {
          ...emailsState,
          isError: false,
          errorMessage: '',
        }
      })
    );
    
    dispatch({
      key: UpdateCheckInInputFormKey.DIGITAL_BOX,
      payload: {
        ...digitalBoxState,
        isContentVisible: isShown,
      }
    });
  }

  const onShowCardListBoxContent = (isShown: boolean) => {
    const card1 = card1State.value.trim();
    if (isShown || !digitalBoxState.isContentVisible) {
        setIsDisabledSaveButton(!isShown);
    }
    
    !card1 && (
      dispatch({
        key: UpdateCheckInInputFormKey.CARD_1,
        payload: {
          ...card1State,
          isError: false,
          errorMessage: '',
        }
      })
    );
    
    dispatch({
      key: UpdateCheckInInputFormKey.CARD_LIST_BOX,
      payload: {
        ...cardListBoxState,
        isContentVisible: isShown,
      }
    });
    
    if (!isShown) {
      setResetAllPayload(UpdateCheckInInputFormKey.CARD_1);
      setResetAllPayload(UpdateCheckInInputFormKey.CARD_2);
      setResetAllPayload(UpdateCheckInInputFormKey.CARD_3);
    }
  }

  const onChangeEmailValue = async (email: string) => {
    const validatedEmail = await validateEmailFormat(emailsState.value)
    dispatch({
      key: UpdateCheckInInputFormKey.EMAILS,
      payload: {
        ...emailsState,
        errorMessage: '',
        value: email,
        isValid: validatedEmail,
        isError: false,
      }
    })
  };

  const setValue = (key: UpdateCheckInInputFormKey, value: string) => {
    dispatch({
      key: key,
      payload: {
        ...updateCheckInStates[key],
        value: value,
      } as UpdateCheckInFormTextInputCardsProps |
           UpdateCheckInFormTextInputValueProps
    });
  }

  const onChangeCard1Value = (card: string) => {
    setValue(UpdateCheckInInputFormKey.CARD_1, card);
  };

  const onChangeCard2Value = (card: string) => {
    setValue(UpdateCheckInInputFormKey.CARD_2, card);
  };

  const onChangeCard3Value = (card: string) => {
    setValue(UpdateCheckInInputFormKey.CARD_3, card);
  };

  const modalCardTitle: any = {
    [UpdateCheckInInputFormKey.CARD_1]: checkInCard1Label,
    [UpdateCheckInInputFormKey.CARD_2]: checkInCard2Label,
    [UpdateCheckInInputFormKey.CARD_3]: checkInCard3Label,
  }

  const onShowQrModal = (key: UpdateCardKey) => {
    dispatch({
      key: UpdateCheckInInputFormKey.QR_MODAL,
      payload: {
        ...qrModalState,
        selectedCard: key,
        title: t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_MODAL_TITLE_QR', {
          cardName: modalCardTitle[key]
        }),
        isLoading: true,
        isVisible: true,
        isFollowingScan: true,
      }
    });
  };

  const onCloseQrModal = () => {
    dispatch({
      key: UpdateCheckInInputFormKey.QR_MODAL,
      payload: {
        ...qrModalState,
        isVisible: false,
      }
    })
  };
  
  const setFollowingCard = () => {
    setTimeout(() => {
      dispatch({
        key: UpdateCheckInInputFormKey.QR_MODAL,
        payload: {
          ...qrModalState,
          isLoading: false,
        }
      });
    }, DELAY_FOLLOWING_CARD);
  };

  const onScanQrModal = (value: string) => {
    dispatch({
      key: qrModalState.selectedCard,
      payload: {
        ...updateCheckInStates[qrModalState.selectedCard],
        value,
      } as UpdateCheckInFormTextInputCardsProps
    });
    
    if (!value) {
      dispatch({
        key: UpdateCheckInInputFormKey.QR_MODAL,
        payload: {
          ...qrModalState,
          isLoading: true,
        }
      });
    }

    if (value && qrModalState.isFollowingScan) {
      const cardKey = getFollowingKeyUpdate(qrModalState.selectedCard);
      
      cardKey && (
        dispatch({
          key: UpdateCheckInInputFormKey.QR_MODAL,
          payload: {
            ...qrModalState,
            isLoading: true,
            selectedCard: cardKey,
            title: t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_MODAL_TITLE_QR', {
              cardName: modalCardTitle[cardKey]
            }),
          }
        })
      );
      
      !cardKey && (
        dispatch({
          key: UpdateCheckInInputFormKey.QR_MODAL,
          payload: {
            ...qrModalState,
            isVisible: false,
          }
        })
      )
    }
    
    if (value && !qrModalState.isFollowingScan) {
      dispatch({
        key: UpdateCheckInInputFormKey.QR_MODAL,
        payload: {
          ...qrModalState,
          isVisible: false,
        }
      });
    }
  };

  const onSave = () => {
    const data: UpdateCheckInRoomModel = {
      assetId: selectedRoom.id,
      nfcCodeList: [card1State.value, card2State.value, card3State.value],
      emailList: emailsState.valueList,
    };
    const rest = {
      accessUid,
      data,
    };
    setIsLoading(true);
    UpdateCheckInRoom({
      token,
      host,
      ...rest
    })
      .then(() => {
        setTimeout(() => {
          setIsVisible(false);
          setIsEdited(true);
          setIsLoading(false)
          messageAtom.success(t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_SUCCESS'), 5)
        }, 1000)
      })
      .catch(error => {
         setIsLoading(false)
         messageAtom.error(t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_ERROR'), 5);
      })
  };

  const onRemoveEmailItemList = (emailItem: string) => {
    const emails = [...emailsState.valueList];
    
    dispatch({
      key: UpdateCheckInInputFormKey.EMAILS,
      payload: {
        ...emailsState,
        valueList: emails.filter(item => emailItem !== item),
        isError: false,
        errorMessage: '',
      }
    });
  };

  const onEmailSelected = async (email: string) => {
    const validatedEmail = await validateEmailFormat(emailsState.value);
    
    if (validatedEmail) {
      const emails = [...emailsState.valueList];
      emails.push(email);
      
      dispatch({
        key: UpdateCheckInInputFormKey.EMAILS,
        payload: {
          ...emailsState,
          valueList: emails,
          value: '',
          isValid: false,
          isError: false,
          errorMessage: '',
        }
      });
    }
    
    if (email && !validatedEmail) {
      dispatch({
        key: UpdateCheckInInputFormKey.EMAILS,
        payload: {
          ...emailsState,
          value: email,
          isValid: false,
          isError: true,
          errorMessage: checkInEmailErrorFormatText,
        }
      });
    }
  };

  const onRefresh = () => {
    dispatch({
      key: UpdateCheckInInputFormKey.QR_MODAL,
      payload: {
        ...qrModalState,
        isLoading: true,
      }
    })
  };

  const onFollowingScan = (checked: boolean) => {
    dispatch({
      key: UpdateCheckInInputFormKey.QR_MODAL,
      payload: {
        ...qrModalState,
        isLoading: true,
        isFollowingScan: checked,
      }
    })
  };

  useEffect(() => {
    qrModalState.isLoading && setFollowingCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrModalState.isLoading]);

  useEffect(() => {
    const isButtonEnabled =
      !!updateCheckInStates[UpdateCheckInInputFormKey.CARD_1].value ||
      !!updateCheckInStates[UpdateCheckInInputFormKey.CARD_2].value ||
      !!updateCheckInStates[UpdateCheckInInputFormKey.CARD_3].value
    setIsDisabledSaveButton(!isButtonEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    updateCheckInStates[UpdateCheckInInputFormKey.CARD_1].value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    updateCheckInStates[UpdateCheckInInputFormKey.CARD_2].value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    updateCheckInStates[UpdateCheckInInputFormKey.CARD_3].value,
  ]);

  rangeState.onChangeRange = onChangeRange;
  startTimeState.onChangeTime = onChangeStartTime;
  endTimeState.onChangeTime = onChangeEndTime;
  digitalBoxState.onShowContent = onShowDigitalBoxContent;
  emailsState.onEmailSelected = onEmailSelected;
  emailsState.onChangeEmail = onChangeEmailValue;
  emailsState.onRemoveEmailItemList = onRemoveEmailItemList;
  cardListBoxState.onShowContent = onShowCardListBoxContent;
  card1State.onChangeValue = onChangeCard1Value;
  card2State.onChangeValue = onChangeCard2Value;
  card3State.onChangeValue = onChangeCard3Value;
  qrModalState.onCancel = onCloseQrModal;
  qrModalState.onScanQr = onScanQrModal;
  qrModalState.onRefresh = onRefresh;
  qrModalState.onFollowingScan = onFollowingScan;
  
  const drawer: Drawer = {
    closeButtonText,
    keysSectionDescription,
    keysSectionTitle,
    requiredFields,
    saveButtonText,
    title,
    selectedRoom,
    onClose,
    onUpdateCheckInRoom,
    onShowQr: onShowQrModal,
    onSave,
    isDisabledSaveButton,
    isLoading,
    isVisible,
    isEdited: isEdited,
  };

  return {
      drawer,
      card1: updateCheckInStates[UpdateCheckInInputFormKey.CARD_1],
      card2: updateCheckInStates[UpdateCheckInInputFormKey.CARD_2],
      card3: updateCheckInStates[UpdateCheckInInputFormKey.CARD_3],
      cardListBox: updateCheckInStates[UpdateCheckInInputFormKey.CARD_LIST_BOX],
      digitalBox: updateCheckInStates[UpdateCheckInInputFormKey.DIGITAL_BOX],
      emails: updateCheckInStates[UpdateCheckInInputFormKey.EMAILS],
      endTime: updateCheckInStates[UpdateCheckInInputFormKey.END_TIME],
      startTime: updateCheckInStates[UpdateCheckInInputFormKey.START_TIME],
      rangeDate: updateCheckInStates[UpdateCheckInInputFormKey.RANGE],
      qrModal: updateCheckInStates[UpdateCheckInInputFormKey.QR_MODAL],
  }
}
