import { Moment } from 'moment';
import { CheckInRoomModel } from 'core/domain/hotels/models';
import { CheckInHistoryCustomDataModel } from 'components/pages/AccessesVerticals/hotel/CheckInListTab/resources/utils';
import { InputRef } from 'antd';

export enum UpdateCheckInInputFormKey {
  CARD_1 = 'card1',
  CARD_2 = 'card2',
  CARD_3 = 'card3',
  CARD_LIST_BOX = 'cardListBox',
  DIGITAL_BOX = 'digitalBox',
  EMAILS = 'emails',
  END_TIME = 'endTime',
  QR_MODAL = 'qrModal',
  RANGE = 'rangeDate',
  START_TIME = 'startTime'
}

export type UpdateCardKey = UpdateCheckInInputFormKey.CARD_1 | UpdateCheckInInputFormKey.CARD_2 | UpdateCheckInInputFormKey.CARD_3;

export interface CheckInModalForceProps {
  acceptButtonText: string;
  cancelButtonText: string;
  checkInData: CheckInRoomModel;
  description: string;
  title: string;
  onAccept: () => void;
  onCancel: () => void;
  isLoading: boolean;
  isVisible: boolean;
}

export interface UpdateCheckInQrModalProps {
  title: string;
  cancelText: string;
  refreshText: string;
  checkText: string;
  selectedCard: UpdateCardKey;
  onCancel: () => void;
  onScanQr: (value: string) => void;
  onRefresh: () => void;
  onFollowingScan: (checked: boolean) => void;
  isVisible: boolean;
  isLoading: boolean;
  isFollowingScan: boolean;
}

export interface UpdateCheckInFormTextInputProps {
  errorMessage: string;
  label: string;
  placeholder: string;
  type?: string;
  isDisabled: boolean;
  isError: boolean;
  isRequired: boolean;
}

export interface UpdateCheckInFormEmailInputValueProps extends UpdateCheckInFormTextInputProps {
  value: string;
  valueList: string[];
  onChangeEmail: (value: string) => void;
  onRemoveEmailItemList: (emailItem: string) => void;
  onEmailSelected: (emailItem: string) => void;
  isValid: boolean;
}

export interface UpdateCheckInFormTextInputValueProps extends UpdateCheckInFormTextInputProps {
  value: string;
  onChangeValue: (value: string) => void;
}

export interface UpdateCheckInFormTextInputRangeProps extends UpdateCheckInFormTextInputProps {
  formatDate: string;
  values: Moment[];
  onChangeRange: (event: Moment[]) => void;
  onDisabledDate: (current: Moment) => boolean;
}

export interface UpdateCheckInFormTextInputTimeProps extends UpdateCheckInFormTextInputProps {
  formatTime: string;
  value: Moment | null;
  onChangeTime: ((value: Moment | null, dateString: string) => void) | undefined;
  onDisabledTime: (date: Moment | null) => {
    disabledHours: () => number[];
    disabledMinutes: () => number[];
  } | {
    disabledHours?: undefined;
    disabledMinutes?: undefined;
  };
}

export interface UpdateCheckInFormTextInputCardsProps extends UpdateCheckInFormTextInputProps {
  value: string;
  key: UpdateCardKey;
  followingKey: UpdateCardKey | null;
  ref: InputRef | null;
  onChangeValue: (value: string) => void;
}

export interface UpdateCheckInFormBoxProps {
  title: string;
  onShowContent: (isShow: boolean) => void;
  isContentVisible: boolean;
  isError: boolean;
}

export interface UpdateCheckInInitialStatesModel {
  [UpdateCheckInInputFormKey.QR_MODAL]: UpdateCheckInQrModalProps;
  [UpdateCheckInInputFormKey.RANGE]: UpdateCheckInFormTextInputRangeProps;
  [UpdateCheckInInputFormKey.START_TIME]: UpdateCheckInFormTextInputTimeProps;
  [UpdateCheckInInputFormKey.END_TIME]: UpdateCheckInFormTextInputTimeProps;
  [UpdateCheckInInputFormKey.DIGITAL_BOX]: UpdateCheckInFormBoxProps;
  [UpdateCheckInInputFormKey.EMAILS]: UpdateCheckInFormEmailInputValueProps;
  [UpdateCheckInInputFormKey.CARD_LIST_BOX]: UpdateCheckInFormBoxProps;
  [UpdateCheckInInputFormKey.CARD_1]: UpdateCheckInFormTextInputCardsProps;
  [UpdateCheckInInputFormKey.CARD_2]: UpdateCheckInFormTextInputCardsProps;
  [UpdateCheckInInputFormKey.CARD_3]: UpdateCheckInFormTextInputCardsProps;
}

export interface Drawer {
  closeButtonText: string;
  keysSectionDescription: string;
  keysSectionTitle: string;
  requiredFields: string;
  saveButtonText: string;
  title: string;
  selectedRoom: { name: string; id: string; };
  onClose: () => void;
  onUpdateCheckInRoom: (item: CheckInHistoryCustomDataModel) => void;
  onShowQr: (key: UpdateCardKey) => void;
  onSave: () => void;
  isDisabledSaveButton: boolean;
  isLoading: boolean;
  isVisible: boolean;
  isEdited: boolean;
}
