import { CheckInCreateRoomDrawerFormValues } from 'components/organisms/CheckInCreateRoomDrawer';
import { useReducer, Reducer, useEffect } from 'react';
import {
  CheckInModalForceProps,
  CheckInQrModalProps,
  CheckInFormBoxProps,
  CheckInFormTextInputCardsProps,
  CheckInFormTextInputRangeProps,
  CheckInFormTextInputTimeProps,
  CheckInFormTextInputValueProps,
  CheckInDrawerProps,
  CheckInFormEmailInputValueProps,
  CardKeys
} from './models';
import { CheckInStateKey, CheckInInitialStatesModel } from './models';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { messageAtom } from 'components/atoms/MessageAtom';
import { CheckInRoom } from 'core/domain/hotels/repository/checkInRoom';
import { CheckInRoomModel } from 'core/domain/hotels/models';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';
import {
  getFollowingKey,
  getInitialCard,
  getInitialCardListBox,
  getInitialDigitalBox,
  getInitialDrawer,
  getInitialEmails,
  getInitialEndTime,
  getInitialModalForce,
  getInitialQrModal,
  getInitialRange,
  getInitialStartTime,
} from './utils';
import i18n from 'config/i18n';
import moment from 'moment';
import { TIME_FORMAT } from 'constants/date';

const DELAY_FOLLOWING_CARD = 1000;

export const accessHotelCheckInitialState: CheckInInitialStatesModel = {
  [CheckInStateKey.DRAWER]: getInitialDrawer(),
  [CheckInStateKey.MODAL_FORCE]: getInitialModalForce(),
  [CheckInStateKey.QR_MODAL]: getInitialQrModal(),
  [CheckInStateKey.RANGE]: getInitialRange(),
  [CheckInStateKey.START_TIME]: getInitialStartTime(),
  [CheckInStateKey.END_TIME]: getInitialEndTime(),
  [CheckInStateKey.DIGITAL_BOX]: getInitialDigitalBox(),
  [CheckInStateKey.EMAILS]: getInitialEmails(),
  [CheckInStateKey.CARD_LIST_BOX]: getInitialCardListBox(),
  [CheckInStateKey.CARD_1]: getInitialCard({
    label: i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_1_LABEL'),
    key: CheckInStateKey.CARD_1,
    isRequired: true,
  }),
  [CheckInStateKey.CARD_2]: getInitialCard({
    label: i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_2_LABEL'),
    key: CheckInStateKey.CARD_2,
  }),
  [CheckInStateKey.CARD_3]: getInitialCard({
    label: i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_3_LABEL'),
    key: CheckInStateKey.CARD_3,
  }),
};

export interface Action {
  key: string;
  payload: CheckInDrawerProps |
    CheckInModalForceProps |
    CheckInQrModalProps |
    CheckInQrModalProps |
    CheckInFormTextInputRangeProps |
    CheckInFormTextInputTimeProps |
    CheckInFormBoxProps |
    CheckInFormTextInputValueProps |
    CheckInFormEmailInputValueProps |
    CheckInFormTextInputCardsProps;
}

const reducer = (state: CheckInInitialStatesModel, action: Action) => ({ ...state, [action.key]: action.payload });

const validateEmailFormat = async (value: string): Promise<boolean> => {
  const email = value.trim();
  const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailValidationRegex.test(email);
};

const checkInRoomValuesToPayload = ({
  emails,
  rangeDate,
  startTime,
  endTime,
  card1,
  card2,
  card3,
  room,
}: CheckInCreateRoomDrawerFormValues): CheckInRoomModel => {
  const validFrom = rangeDate[0].set({
    hour: startTime.hour(),
    minute: startTime.minute(),
  });

  const validUntil = rangeDate[1].set({
    hour: endTime.hour(),
    minute: endTime.minute(),
  });

  const nfc = [card1, card2, card3].filter((card) => !!card);

  return {
    attributes: {
      validFrom: validFrom.unix(),
      validUntil: validUntil.unix(),
      assetId: room,
      emails: emails,
      codes: nfc,
      forceSaveMode: false
    }
  }
}

export const useCheckInRoom = () => {
  const { t } = useTranslation();
  const { token } = useUserSession();
  const { host } = useEnvironment();
  const [
    checkInStates, dispatch
  ] = useReducer<Reducer<CheckInInitialStatesModel, Action>>(
    reducer, accessHotelCheckInitialState
  );
  
  const drawerState = checkInStates[CheckInStateKey.DRAWER];
  const modalForceState = checkInStates[CheckInStateKey.MODAL_FORCE];
  const rangeState = checkInStates[CheckInStateKey.RANGE];
  const startTimeState = checkInStates[CheckInStateKey.START_TIME];
  const endTimeState = checkInStates[CheckInStateKey.END_TIME];
  const digitalBoxState = checkInStates[CheckInStateKey.DIGITAL_BOX];
  const cardListBoxState = checkInStates[CheckInStateKey.CARD_LIST_BOX];
  const emailsState = checkInStates[CheckInStateKey.EMAILS];
  const card1State = checkInStates[CheckInStateKey.CARD_1];
  const card2State = checkInStates[CheckInStateKey.CARD_2];
  const card3State = checkInStates[CheckInStateKey.CARD_3];
  const qrModalState = checkInStates[CheckInStateKey.QR_MODAL];

  const checkInCard1Label = t('_ACCESSES_HOTEL_CHECK_IN_CARD_1_LABEL');
  const checkInCard2Label = t('_ACCESSES_HOTEL_CHECK_IN_CARD_2_LABEL');
  const checkInCard3Label = t('_ACCESSES_HOTEL_CHECK_IN_CARD_3_LABEL');
  const checkInRequiredFieldErrorText = t('_ACCESSES_HOTEL_CHECK_IN_REQUIRED_FIELD');
  const checkInEmailErrorFormatText = t('_ACCESSES_HOTEL_CHECK_IN_EMAIL_ERROR_FORMAT');

  const setResetAllPayload = (key: CheckInStateKey) => {
    dispatch({
      key,
      payload: accessHotelCheckInitialState[key]
    });
  }

  const onOpenCheckInDrawer = (name: string, roomId: string) => {
    setResetAllPayload(CheckInStateKey.RANGE);
    setResetAllPayload(CheckInStateKey.END_TIME);
    setResetAllPayload(CheckInStateKey.DIGITAL_BOX);
    setResetAllPayload(CheckInStateKey.EMAILS)
    setResetAllPayload(CheckInStateKey.CARD_LIST_BOX)
    setResetAllPayload(CheckInStateKey.CARD_1);
    setResetAllPayload(CheckInStateKey.CARD_2);
    setResetAllPayload(CheckInStateKey.CARD_3);
    setResetAllPayload(CheckInStateKey.MODAL_FORCE);
    
    dispatch({
      key: CheckInStateKey.START_TIME,
      payload: {
        ...getInitialStartTime(),
        value: moment(moment(), TIME_FORMAT)
      }
    });
    dispatch({
      key: CheckInStateKey.DRAWER,
      payload: {
        ...drawerState,
        title: t('_ACCESS_HOTEL_CHECK_IN_DRAWER_TITLE_ROOM_NAME', { roomName: name }),
        selectedRoom: { name, id: roomId },
        isVisible: true,
        isDisabledSaveButton: true,
        isLoading: false,
      }
    });
  }

  const onCloseCheckInDrawer = () => {
    dispatch({
      key: CheckInStateKey.DRAWER,
      payload: {
        ...drawerState,
        isVisible: false,
      }
    });
  }

  const onChangeRange = (values: Moment[] | null) => {
    dispatch({
      key: CheckInStateKey.RANGE,
      payload: {
        ...rangeState,
        isError: !values?.length,
        errorMessage: !values?.length ? checkInRequiredFieldErrorText : '',
        values: values ?? [],
      }
    });
  }

  const setResetError = (key: CheckInStateKey) => {
    dispatch({
      key,
      payload: {
        ...checkInStates[key],
        isError: false,
        errorMessage: '',
      } as CheckInFormBoxProps |
           CheckInFormTextInputTimeProps |
           CheckInFormTextInputCardsProps |
           CheckInFormTextInputRangeProps |
           CheckInFormTextInputValueProps
    });
  }

  const onChangeTime = (key: CheckInStateKey, value: Moment | null) => {
    dispatch({
      key,
      payload: {
        ...checkInStates[key],
        isError: !value,
        errorMessage: !value ? checkInRequiredFieldErrorText : '',
        value,
      } as CheckInFormTextInputTimeProps
    });
  }

  const onChangeStartTime = (value: Moment | null) => {
    !!value && setResetError(CheckInStateKey.START_TIME);
    onChangeTime(CheckInStateKey.START_TIME, value)
  }

  const onChangeEndTime = (value: Moment | null) => {
    !!value && setResetError(CheckInStateKey.END_TIME);
    onChangeTime(CheckInStateKey.END_TIME, value)
  }

  const onShowDigitalBoxContent = (isShown: boolean) => {
    const email = emailsState.value.trim();
    
    if (isShown || !cardListBoxState.isContentVisible) {
      dispatch({
        key: CheckInStateKey.DRAWER,
        payload: {
          ...drawerState,
          isDisabledSaveButton: !isShown,
        }
      });
    }
    
    !email && (
      dispatch({
        key: CheckInStateKey.EMAILS,
        payload: {
          ...emailsState,
          isError: false,
          errorMessage: '',
        }
      })
    );
    
    dispatch({
      key: CheckInStateKey.DIGITAL_BOX,
      payload: {
        ...digitalBoxState,
        isContentVisible: isShown,
      }
    });
  }

  const onShowCardListBoxContent = (isShown: boolean) => {
    const card1 = card1State.value.trim();
    if (isShown || !digitalBoxState.isContentVisible) {
      dispatch({
        key: CheckInStateKey.DRAWER,
        payload: {
          ...drawerState,
          isDisabledSaveButton: !isShown,
        }
      });
    }
    
    !card1 && (
      dispatch({
        key: CheckInStateKey.CARD_1,
        payload: {
          ...card1State,
          isError: false,
          errorMessage: '',
        }
      })
    );
    
    dispatch({
      key: CheckInStateKey.CARD_LIST_BOX,
      payload: {
        ...cardListBoxState,
        isContentVisible: isShown,
      }
    });
    
    if (!isShown) {
      setResetAllPayload(CheckInStateKey.CARD_1);
      setResetAllPayload(CheckInStateKey.CARD_2);
      setResetAllPayload(CheckInStateKey.CARD_3);
    }
  }

  const onChangeEmailValue = async (email: string) => {
    const validatedEmail = await validateEmailFormat(emailsState.value)
    dispatch({
      key: CheckInStateKey.EMAILS,
      payload: {
        ...emailsState,
        errorMessage: '',
        value: email,
        isValid: validatedEmail,
        isError: false,
      }
    })
  };

  const setValue = (key: CheckInStateKey, value: string) => {
    dispatch({
      key: key,
      payload: {
        ...checkInStates[key],
        value: value,
      } as CheckInFormTextInputCardsProps |
           CheckInFormTextInputValueProps
    });
  }

  const onChangeCard1Value = (card: string) => {
    setValue(CheckInStateKey.CARD_1, card);
  };

  const onChangeCard2Value = (card: string) => {
    setValue(CheckInStateKey.CARD_2, card);
  };

  const onChangeCard3Value = (card: string) => {
    setValue(CheckInStateKey.CARD_3, card);
  };

  const modalCardTitle: any = {
    [CheckInStateKey.CARD_1]: checkInCard1Label,
    [CheckInStateKey.CARD_2]: checkInCard2Label,
    [CheckInStateKey.CARD_3]: checkInCard3Label,
  }

  const onShowQrModal = (key: CardKeys) => {
    dispatch({
      key: CheckInStateKey.QR_MODAL,
      payload: {
        ...qrModalState,
        selectedCard: key,
        title: t('_ACCESSES_HOTEL_CHECK_IN_MODAL_TITLE_QR', {
          cardName: modalCardTitle[key]
        }),
        isLoading: true,
        isVisible: true,
        isFollowingScan: true,
      }
    });
  }

  const onCloseQrModal = () => {
    dispatch({
      key: CheckInStateKey.QR_MODAL,
      payload: {
        ...qrModalState,
        isVisible: false,
      }
    });
  }
  
  const setFollowingCard = () => {
    setTimeout(() => {
      dispatch({
        key: CheckInStateKey.QR_MODAL,
        payload: {
          ...qrModalState,
          isLoading: false,
        }
      });
    }, DELAY_FOLLOWING_CARD);
  }

  useEffect(() => {
    qrModalState.isLoading && setFollowingCard();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrModalState.isLoading])

  const onScanQrModal = (value: string) => {
    dispatch({
      key: qrModalState.selectedCard,
      payload: {
        ...checkInStates[qrModalState.selectedCard],
        value,
      } as CheckInFormTextInputCardsProps
    });
    
    if (!value) {
      dispatch({
        key: CheckInStateKey.QR_MODAL,
        payload: {
          ...qrModalState,
          isLoading: true,
        }
      });
    }

    if (value && qrModalState.isFollowingScan) {
      const cardKey = getFollowingKey(qrModalState.selectedCard);
      
      cardKey && (
        dispatch({
          key: CheckInStateKey.QR_MODAL,
          payload: {
            ...qrModalState,
            isLoading: true,
            selectedCard: cardKey,
            title: t('_ACCESSES_HOTEL_CHECK_IN_MODAL_TITLE_QR', {
              cardName: modalCardTitle[cardKey]
            }),
          }
        })
      );
      
      !cardKey && (
        dispatch({
          key: CheckInStateKey.QR_MODAL,
          payload: {
            ...qrModalState,
            isVisible: false,
          }
        })
      )
    }
    
    if (value && !qrModalState.isFollowingScan) {
      dispatch({
        key: CheckInStateKey.QR_MODAL,
        payload: {
          ...qrModalState,
          isVisible: false,
        }
      });
    }
  }

  const setRequiredError = (
    key: CheckInStateKey,
    state:
      CheckInFormEmailInputValueProps |
      CheckInFormTextInputRangeProps |
      CheckInFormTextInputTimeProps |
      CheckInFormTextInputValueProps |
      CheckInFormTextInputCardsProps,
  ) => {
    dispatch({
      key,
      payload: {
        ...state,
        isError: true,
        errorMessage: checkInRequiredFieldErrorText,
      }
    });
  }

  const setResetCard1Error = () => {
    dispatch({
      key: CheckInStateKey.CARD_1,
      payload: {
        ...card1State,
        isError: false,
        errorMessage: '',
      }
    });
  }

  const setUsedCheckInRoom = (data: CheckInRoomModel) => {
    dispatch({
      key: CheckInStateKey.MODAL_FORCE,
      payload: {
        ...modalForceState,
        title: t('_ACCESSES_HOTEL_CHECK_IN_FORCE_SAVE_MODAL_TITLE', { roomName: drawerState.selectedRoom.name }),
        description: t('_ACCESSES_HOTEL_CHECK_IN_FORCE_SAVE_MODAL_DESCRIPTION', { roomName: drawerState.selectedRoom.name }),
        checkInData: data,
        isVisible: true,
      }
    });
  }

  const setUnusedCheckInRoom = () => {
    dispatch({
      key: CheckInStateKey.DRAWER,
      payload: {
        ...drawerState,
        isVisible: false,
      }
    });
    messageAtom.success(t('_ACCESS_HOTEL_CHECK_IN_DRAWER_SUCCESS'), 5);
  }

  const sendMessageError = (code: number) => {
    if (code === 406) {
      return messageAtom.error(t('_ACCESS_HOTEL_CHECK_IN_DRAWER_406_ERROR'), 5);
    }
    if (code === 422) {
      return messageAtom.error(t('_ACCESS_HOTEL_CHECK_IN_DRAWER_FORMAT_CARD_ERROR'), 5);
    }
    messageAtom.error(t('_ACCESS_HOTEL_CHECK_IN_DRAWER_ERROR'), 5);
  }

  const onSaveDrawer = async () => {
    const areThereRange = !!rangeState.values.length;
    const isThereStartTime = !!startTimeState.value;
    const isThereEndTime = !!endTimeState.value;
    const isThereDigitalBox = digitalBoxState.isContentVisible;
    const isThereEmailList = !!emailsState.valueList.length;
    const areThereCardList = cardListBoxState.isContentVisible;
    const isCard1 = !!card1State.value.trim();
    let isError = false;

    if (!areThereRange) {
      setRequiredError(CheckInStateKey.RANGE, rangeState);
      isError = true;
    }
    
    if (!isThereStartTime) {
      setRequiredError(CheckInStateKey.START_TIME, startTimeState)
      isError = true;
    }
    
    if (!isThereEndTime) {
      setRequiredError(CheckInStateKey.END_TIME, endTimeState);
      isError = true;
    }
    
    if (isThereDigitalBox && !isThereEmailList) {
      emailsState.isError || setRequiredError(CheckInStateKey.EMAILS, emailsState);
      isError = true;
    }
    
    if (areThereCardList && !isCard1) {
      setRequiredError(CheckInStateKey.CARD_1, card1State)
      isError = true;
    }
    
    areThereCardList && isCard1 && setResetCard1Error();
    
    if (!isError) {
      const data = checkInRoomValuesToPayload({
        [CheckInStateKey.RANGE]: rangeState.values,
        [CheckInStateKey.START_TIME]: startTimeState.value!,
        [CheckInStateKey.END_TIME]: endTimeState.value!,
        [CheckInStateKey.EMAILS]: emailsState.valueList,
        [CheckInStateKey.CARD_1]: card1State.value,
        [CheckInStateKey.CARD_2]: card2State.value,
        [CheckInStateKey.CARD_3]: card3State.value,
        room: drawerState.selectedRoom.id,
      })
      
      try {
        dispatch({
          key: CheckInStateKey.DRAWER,
          payload: {
            ...drawerState,
            isLoading: true,
          }
        });
        const { hasUsed } = await CheckInRoom({ data, token, host });
        
        !hasUsed && setUnusedCheckInRoom();
        hasUsed && setUsedCheckInRoom(data);
      } catch (error: any) {
        dispatch({
          key: CheckInStateKey.DRAWER,
          payload: {
            ...drawerState,
            isLoading: false,
          }
        });
        sendMessageError(error.code)
      } 
    }
  }

  const onForceSaveModalAccept = async () => {
    dispatch({
      key: CheckInStateKey.MODAL_FORCE,
      payload: {
        ...modalForceState,
        isLoading: true,
      }
    });
    const { attributes } = modalForceState.checkInData;
    try {
      await CheckInRoom({
        data: { attributes: { ...attributes, forceSaveMode: true } },
        token,
        host
      });
      dispatch({
        key: CheckInStateKey.MODAL_FORCE,
        payload: {
          ...modalForceState,
          isVisible: false,
        }
      });
      dispatch({
        key: CheckInStateKey.DRAWER,
        payload: {
          ...drawerState,
          isVisible: false,
        }
      });
      messageAtom.success(t('_ACCESS_HOTEL_CHECK_IN_DRAWER_SUCCESS'), 5);
    } catch (error: any) {
      dispatch({
        key: CheckInStateKey.DRAWER,
        payload: {
          ...drawerState,
          isLoading: false,
        }
      });
      sendMessageError(error.code)
    }
  }

  const onForceSaveModalCancel = () => {
    dispatch({
      key: CheckInStateKey.MODAL_FORCE,
      payload: {
        ...modalForceState,
        isVisible: false,
      }
    });
    
    dispatch({
      key: CheckInStateKey.DRAWER,
      payload: {
        ...drawerState,
        isLoading: false,
      }
    });
  }

  const onRemoveEmailItemList = (emailItem: string) => {
    const emails = [...emailsState.valueList];
    
    dispatch({
      key: CheckInStateKey.EMAILS,
      payload: {
        ...emailsState,
        valueList: emails.filter(item => emailItem !== item),
        isError: false,
        errorMessage: '',
      }
    });
  }

  const onEmailSelected = async (email: string) => {
    const validatedEmail = await validateEmailFormat(emailsState.value);
    
    if (validatedEmail) {
      const emails = [...emailsState.valueList];
      emails.push(email);
      
      dispatch({
        key: CheckInStateKey.EMAILS,
        payload: {
          ...emailsState,
          valueList: emails,
          value: '',
          isValid: false,
          isError: false,
          errorMessage: '',
        }
      });
    }
    
    if (email && !validatedEmail) {
      dispatch({
        key: CheckInStateKey.EMAILS,
        payload: {
          ...emailsState,
          value: email,
          isValid: false,
          isError: true,
          errorMessage: checkInEmailErrorFormatText,
        }
      });
    }
  }

  const onRefresh = () => {
    dispatch({
      key: CheckInStateKey.QR_MODAL,
      payload: {
        ...qrModalState,
        isLoading: true,
      }
    })
  }

  const onFollowingScan = (checked: boolean) => {
    dispatch({
      key: CheckInStateKey.QR_MODAL,
      payload: {
        ...qrModalState,
        isLoading: true,
        isFollowingScan: checked,
      }
    })
  }

  rangeState.onChangeRange = onChangeRange;
  startTimeState.onChangeTime = onChangeStartTime;
  endTimeState.onChangeTime = onChangeEndTime;
  digitalBoxState.onShowContent = onShowDigitalBoxContent;
  emailsState.onEmailSelected = onEmailSelected;
  emailsState.onChangeEmail = onChangeEmailValue;
  emailsState.onRemoveEmailItemList = onRemoveEmailItemList;
  cardListBoxState.onShowContent = onShowCardListBoxContent;
  card1State.onChangeValue = onChangeCard1Value;
  card2State.onChangeValue = onChangeCard2Value;
  card3State.onChangeValue = onChangeCard3Value;
  qrModalState.onCancel = onCloseQrModal;
  qrModalState.onScanQr = onScanQrModal;
  qrModalState.onRefresh = onRefresh;
  qrModalState.onFollowingScan = onFollowingScan;
  drawerState.onCheckInRoom = onOpenCheckInDrawer;
  drawerState.onClose = onCloseCheckInDrawer;
  drawerState.onShowQr = onShowQrModal;
  drawerState.onSave = onSaveDrawer;
  modalForceState.onAccept = onForceSaveModalAccept;
  modalForceState.onCancel = onForceSaveModalCancel;

  return { checkInStates }
}
