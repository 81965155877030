import { FC } from 'react';
import { useCheckInList } from './resources/useCheckInList';
import CheckInListTemplate from 'components/template/CheckInListTemplate';
import AccessHotelKeyReaderDrawer from 'components/organisms/AccessHotelKeyReaderDrawer';
import AccessHotelKeyReaderQRModal from 'components/organisms/AccessHotelKeyReaderQRModal';
import CheckoutRoomModal from 'components/organisms/CheckoutRoomModal';
import CheckInUpdateRoomDrawer from 'components/organisms/CheckInUpdateRoomDrawer';
import CheckInScannerQRModal from 'components/organisms/CheckInScannerQRModal';

const CheckInListTab: FC = () => {
  const {
    keyReaderLiterals,
    qrReaderModalLiterals,
    checkInLIstLiterals,
    checkInList,
    totalPages,
    currentPage,
    pageSize,
    nfcCode,
    keyReaderData,
    columnsCheckInList,
    checkoutSelectedItems,
    checkoutRoomModalLiterals,
    updateCheckInStates,
    onOpenKeyReaderDrawer,
    onChangePage,
    onCloseKeyReaderDrawer,
    onShowQrReader,
    onCancelQrReader,
    onScanQrReader,
    onChangeNfcCode,
    onSelectedCheckout,
    onCheckoutFromReaderKey,
    onAcceptCheckout,
    onNoShowAgainCheckoutModal,
    onCancelCheckout,
    isNotLengthKeyReader,
    isLoading,
    isLoadingKeyReader,
    isVisibleKeyReaderDrawer,
    isVisibleQrReaderModal,
    isDisabledCheckoutButton,
    isLoadingCheckout,
    isVisibleCheckoutModal,
    isNoShowAgainCheckoutModal,
  } = useCheckInList();

  return (
    <>
      <CheckInListTemplate
        {...checkInLIstLiterals}
        checkInList={checkInList}
        totalPages={totalPages}
        pageSize={pageSize}
        currentPage={currentPage}
        columns={columnsCheckInList}
        onKeyReader={onOpenKeyReaderDrawer}
        onChangePage={onChangePage}
        loading={isLoading}
      />
      <AccessHotelKeyReaderDrawer
        {...keyReaderLiterals}
        keyReaderData={keyReaderData}
        nfcCode={nfcCode}
        checkoutSelectedItems={checkoutSelectedItems}
        onCheckout={onCheckoutFromReaderKey}
        onClose={onCloseKeyReaderDrawer}
        onShowQr={onShowQrReader}
        onChangeNfcCode={onChangeNfcCode}
        onSelected={onSelectedCheckout}
        isNotLengthKeyReader={isNotLengthKeyReader}
        isLoading={isLoadingKeyReader}
        isLoadingCheckout={isLoadingCheckout}
        isVisible={isVisibleKeyReaderDrawer}
        isDisabledCheckoutButton={isDisabledCheckoutButton}
      />
      <AccessHotelKeyReaderQRModal
        {...qrReaderModalLiterals}
        onCancel={onCancelQrReader}
        onScanQr={onScanQrReader}
        visible={isVisibleQrReaderModal}
      />
      <CheckoutRoomModal
        {...checkoutRoomModalLiterals}
        onAccept={onAcceptCheckout}
        onAction={onNoShowAgainCheckoutModal}
        onCancel={onCancelCheckout}
        isCheckedAction={isNoShowAgainCheckoutModal}
        isLoading={isLoadingCheckout}
        isVisible={isVisibleCheckoutModal}
      />
      <CheckInUpdateRoomDrawer {...updateCheckInStates} />
      <CheckInScannerQRModal {...updateCheckInStates.qrModal} />
    </>
  )
}

export default CheckInListTab;