import i18n from 'config/i18n';
import {
  CheckInModalForceProps,
  UpdateCheckInFormBoxProps,
  UpdateCheckInFormTextInputRangeProps,
  UpdateCheckInFormTextInputTimeProps,
  UpdateCheckInFormEmailInputValueProps,
  UpdateCheckInInputFormKey,
  UpdateCheckInFormTextInputCardsProps,
  UpdateCheckInQrModalProps,
  UpdateCardKey
} from './models';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/date';
import { disabledDate, disabledDateTime } from 'utils/date';
import moment from 'moment';
import { CheckInRoomModel } from 'core/domain/hotels/models';

export const getInitialDigitalBox = (): UpdateCheckInFormBoxProps => ({
  title: i18n.t('_ACCESS_HOTEL_DRAWER_DIGITAL_BOX_TITLE'),
  onShowContent: () => null,
  isContentVisible: false,
  isError: false,
})

export const getInitialEmails = (): UpdateCheckInFormEmailInputValueProps => ({
  errorMessage: '',
  label: i18n.t('_ACCESS_HOTEL_DRAWER_EMAIL_LABEL'),
  placeholder: i18n.t('_ACCESS_HOTEL_DRAWER_EMAIL_PLACEHOLDER'),
  type: 'email',
  value: '',
  valueList: [],
  onChangeEmail: () => null,
  onRemoveEmailItemList: () => null,
  onEmailSelected: () => null,
  isValid: false,
  isDisabled: false,
  isError: false,
  isRequired: false,
})

export const getInitialCardListBox = (): UpdateCheckInFormBoxProps => ({
  title: i18n.t('_ACCESS_HOTEL_DRAWER_CARD_LIST_BOX_TITLE'),
  onShowContent: () => null,
  isContentVisible: false,
  isError: false,
})

export const cardKeysGroupUpdate: UpdateCardKey[] = [
  UpdateCheckInInputFormKey.CARD_1,
  UpdateCheckInInputFormKey.CARD_2,
  UpdateCheckInInputFormKey.CARD_3
];

export const getFollowingKeyUpdate = (key: UpdateCardKey): UpdateCardKey | null => {
  const index = cardKeysGroupUpdate.indexOf(key);
  const length = cardKeysGroupUpdate.length - 1;
  return index === length ? null : cardKeysGroupUpdate[index + 1];
}


export const getInitialUpdateCard = (
  { label, key, isRequired = false }:
    { label: string, key: UpdateCardKey, isRequired?: boolean }
): UpdateCheckInFormTextInputCardsProps => ({
  key,
  followingKey: getFollowingKeyUpdate(key),
  errorMessage: '',
  label,
  placeholder: i18n.t('_ACCESS_HOTEL_DRAWER_CARD_PLACEHOLDER', { label }),
  value: '',
  ref: null,
  onChangeValue: () => null,
  isDisabled: false,
  isError: false,
  isRequired,
})

export const getInitialModalForce = (): CheckInModalForceProps => ({
  acceptButtonText: i18n.t('_ACCESSES_HOTEL_CHECK_IN_FORCE_SAVE_MODAL_ACCEPT'),
  cancelButtonText: i18n.t('_ACCESSES_HOTEL_CHECK_IN_FORCE_SAVE_MODAL_CANCEL'),
  checkInData: {} as CheckInRoomModel,
  description: '',
  title: '',
  onAccept: () => null,
  onCancel: () => null,
  isLoading: false,
  isVisible: false,
})

export const getInitialUpdateQrModal = (): UpdateCheckInQrModalProps => ({
  title: i18n.t('_ACCESS_HOTEL_MODAL_TITLE_QR'),
  cancelText: i18n.t('_ACCESS_HOTEL_MODAL_CANCEL_QR'),
  checkText: i18n.t('_ACCESS_HOTEL_MODAL_CHECK_BOX_QR'),
  refreshText: i18n.t('_ACCESS_HOTEL_MODAL_REFRESH'),
  selectedCard: UpdateCheckInInputFormKey.CARD_1,
  onScanQr: () => null,
  onCancel: () => null,
  onRefresh: () => null,
  onFollowingScan: () => null,
  isVisible: false,
  isLoading: false,
  isFollowingScan: true,
})


export const getInitialRange = (): UpdateCheckInFormTextInputRangeProps => ({
  errorMessage: '',
  formatDate: DATE_FORMAT,
  label: i18n.t('_ACCESS_HOTEL_DRAWER_RANGE'),
  placeholder: '',
  values: [],
  onDisabledDate: disabledDate,
  onChangeRange: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
})

export const getInitialStartTime = (): UpdateCheckInFormTextInputTimeProps => ({
  errorMessage: '',
  formatTime: TIME_FORMAT,
  label: i18n.t('_ACCESS_HOTEL_DRAWER_START_TIME'),
  placeholder: '',
  value: null,
  onDisabledTime: disabledDateTime,
  onChangeTime: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
})

export const getInitialEndTime = (): UpdateCheckInFormTextInputTimeProps => ({
  errorMessage: '',
  formatTime: TIME_FORMAT,
  label: i18n.t('_ACCESS_HOTEL_DRAWER_END_TIME'),
  placeholder: '',
  value: moment('12:00', TIME_FORMAT),
  onDisabledTime: disabledDateTime,
  onChangeTime: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
})
